'use client'

import { useState, useEffect, useCallback } from 'react'
import Image from 'next/image'

const images = [
  {
    src: "https://travelthru.com/cdn-cgi/imagedelivery/wZpbJM3t8iED5kIISxeUgQ/mobilebanner11png/public",
    alt: "wZpbJM3t8iED5kIISxeUgQ/mobilebanner11png",
  },
  {
    src: "https://travelthru.com/cdn-cgi/imagedelivery/wZpbJM3t8iED5kIISxeUgQ/DSC02420JPG/public",
    alt: "wZpbJM3t8iED5kIISxeUgQ/DSC02420JPG",
  },
  {
    src: "https://travelthru.com/cdn-cgi/imagedelivery/wZpbJM3t8iED5kIISxeUgQ/DSC01860JPG/public",
    alt: "wZpbJM3t8iED5kIISxeUgQ/DSC01860JPG",
  },
];

export default function MobileBG() {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [isTransitioning, setIsTransitioning] = useState(false)

  const nextSlide = useCallback(() => {
    setIsTransitioning(true)
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length)
      setIsTransitioning(false)
    }, 500)  
  }, [])

  useEffect(() => {
    const timer = setInterval(() => {
      nextSlide()
    }, 6000)  

    return () => clearInterval(timer)
  }, [nextSlide])

  return (
    <div className="relative w-full h-full overflow-hidden">
      {images.map((image, index) => (
        <div
          key={image.src}
          className={`absolute inset-0 transition-opacity duration-500 ease-in-out ${index === currentIndex ? 'opacity-100' : 'opacity-0'
            }`}
        >
          <Image
            src={image.src}
            alt={image.alt}
            fill
            className="object-cover"
            priority
          />
        </div>
      ))}
    </div>
  )
}

