import { useState, useEffect } from "react";

export const useGoogleRating = () => {
  const [ggRating, setGgRating] = useState("4.8/5");

  const fetchGoogleRating = async () => {
    try {
      const response = await fetch(
        "https://eu-entities-api.limolink.net/api/Place/autocomplete?text=TravelThru%2C%20275%20Phan%20Hu%E1%BB%B3nh%20%C4%90i%E1%BB%83u%2C%20Ho%C3%A0%20Qu%C3%BD%2C%20Ng%C5%A9%20H%C3%A0nh%20S%C6%A1n%2C%20%C4%90%C3%A0%20N%E1%BA%B5ng%20550000%2C%20Vietnam&field=rating&lat=16.007731637453755&lng=108.2402981390951&language=en&distance=100"
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      const rating = data.result[0]?.rating ?? 4.8;
      return rating;
    } catch (error) {
      console.error("Error fetching rating:", error);
      throw error;
    }
  };

  useEffect(() => {
    fetchGoogleRating().then((rating) => {
      if (rating !== null) {
        setGgRating(`${rating}/5`);
      } else {
        setGgRating("4.8/5");
      }
    });
  }, []);

  return ggRating;
};